$borderRadius: 4px;
$borderWidth: 2px;
$margin: 10px;
$padding: 10px;

@mixin container {
	display: flex;
	width: 100%;
	margin-bottom: $margin;
	margin-top: $margin;
}

@mixin input {
	color: $lightBlack;
	background-color: white;
	padding-left: $padding;
	padding-right: $padding;
	border-style: solid;
	border-color: $lightBlack;
	border-width: $borderWidth;

	&:focus {
		// Select this:focus and this:focus + label
		// The label comes after in the html, so we can use this selector. Render order is reversed using a reversed flex-direction
		&, & + .label {
			outline: black solid 2px;
			border-color: black;
		}

		& {
			color: black;
			background-color: white;
		}

		& + .label {
			color: white;
			background-color: black;
		}
	}
}

@mixin label {
	height: 100%;
	color: $darkWhite;
	background-color: $lightBlack;
	padding-left: $padding;
	padding-right: $padding;
	display: inline-block;
	white-space: nowrap;
}