@import 'colors';
@import 'fonts';

* {
	padding: 0;
	margin: 0;
}

body {
	font-family: 'Umpush';
	width: 100%;
	height: 100%;
	background-color: $darkWhite;
	color: $lightBlack;
}

.dev_banner {
	font-size: larger;
	font-family: 'Courier New', Courier, monospace;
	text-align: center;
	background: repeating-linear-gradient(45deg,
			#e2c02a,
			#e2c02a 10px,
			#c5a414 10px,
			#c5a414 20px);
	position: relative;
	z-index: 999;
}
