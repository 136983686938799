$phone_width: 768px;

@mixin phone {
	@media (max-width: $phone_width) {
		@content
	}
};

@mixin desktop {
	@media (min-width: ($phone_width + 1)) {
		@content
	}
}
