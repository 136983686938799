@import 'colors';

.button {
	color: $lightBlack;
	background-color: white;
	box-shadow: 2px 2px 2px 2px #0000003b;
	transition: margin-right 0.8s, scale 0.2s;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	width: 50px;
	height: 50px;
	
	position: fixed;
	z-index: 20;
	left: auto;
	top: auto;
	right: 30px;
	bottom: 30px;
	margin-right: -100px;
	padding: 15px;

	&:hover {
		scale: 1.2;
	}
}

.hidden {
	margin-right: 0px;
}