@import 'colors';
@import 'inputs';

.container {
	@include container;
	justify-content: center;
	align-items: center;
}

.button {
	padding: 10px;
	margin: 10px;
	border-style: solid;
	border-radius: $borderRadius;
	border-color: $lightBlack;
	color: $lightBlack;
	background-color: white;
	transition: background-color 0.5s;
	cursor: pointer;

	&:hover:not(:disabled) {
		background-color: $lightBlack;
		color: #fff;
		transition: background-color 0.2s;
	}

	&:disabled {
		border-color: gray;
		background-color: $darkWhite;
		color: gray;
		cursor: not-allowed;
	}

	&:focus {
		outline: black solid 2px;
	}
}
