@import 'colors';
@import 'media';

.sarcophage {
	font-size: 23px;
	text-decoration: none;
	color: $lightBlack;
	transition: font-size 1s;
	padding-left: 80px;

	&:hover {
		transition: font-size 0.3s;
		font-size: 30px;
		text-decoration: underline;
		animation: rainbowAnimationFont 20s linear infinite alternate;
	}

	:hover {
		scale: 2;
	}
}

.textScroll {
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	animation: textScroll 50s linear infinite;
}

@keyframes textScroll {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(-50%, 0, 0);
	}
}

.header {
	position: relative;
	width: 100%;
	height: 50px;
	border-bottom: 2px solid $lightBlack;
	display: flex;
	align-items: stretch;
	justify-content: center;
	user-select: none;
	background-color: $darkWhite;
	box-shadow: 0px -5px 20px 20px #0005;
	margin-bottom: 80px;
}

@mixin header-item {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-shrink: 0;
	background-color: white;
	transition: transform 0.2s;
	transform-origin: top;

	@include desktop {
		&:hover {
			animation: rainbowAnimation 20s linear infinite alternate;
			transform: scale(1.2);
			transition: transform 0.2s;
			color: $darkWhite;
			font-weight: bolder;
	
			div {
				width: 80%;
				border-style: none;
			}
		}
	}

	div {
		padding-left: 25px;
		padding-right: 25px;
	}

	@include phone {
		height: auto;
		transform-origin: center;
		margin-right: 0;
		flex-grow: 0;
		border-top: solid $separatorLineWidth;
		div {
			border-left: none !important;
			border-right: none !important;
		}
	}
}

.titleScrolling {
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	height: 100%;

	flex-grow: 5;
	overflow: hidden;

	vertical-align: baseline;


	div {
		height: 100%;

		display: table;

		p {
			display: table-cell;
			vertical-align: middle;
		}

		animation-play-state: running;

		&:hover {
			animation-play-state: paused;
		}
	}
}

$separatorLineWidth: 0.1em;
$separatorLineColor: $lightBlack;

.items {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
}

.invite {
	@include header-item;

	div {
		border-left: calc($separatorLineWidth / 2) solid $separatorLineColor;
		border-right: calc($separatorLineWidth / 2) solid $separatorLineColor;
	}
}

.connexion {
	@include header-item;
	transform-origin: right top;

	margin-right: 15%;
}

.userIdenticon {
	vertical-align: sub;
	margin-right: 10px;
	height: 25px;
	position: relative;
	top: 3px;
}

.logout {
	@include header-item;
	transform-origin: right top;

	margin-right: 15%;

	div {
		border-left: calc($separatorLineWidth / 2) solid $separatorLineColor;
	}
}

.addQuote {
	@include header-item;

	div {
		border-right: calc($separatorLineWidth / 2) solid $separatorLineColor;
	}
}

@include phone {
	.header {
		height: 100%;
		flex-direction: column;
	}

	.items {
		flex-direction: column;
		justify-content: center;
		background-color: white;
	}
}

@keyframes rainbowAnimation {
	0% {
		background-color: #4408c5
	}

	10% {
		background-color: #8c09bc
	}

	20% {
		background-color: #ce0606
	}

	30% {
		background-color: #c56c06
	}

	40% {
		background-color: #caa906
	}

	50% {
		background-color: #aec806
	}

	60% {
		background-color: #7ec609
	}

	70% {
		background-color: #07be71
	}

	80% {
		background-color: #0aadb8
	}

	90% {
		background-color: #0a56ad
	}

	100% {
		background-color: #410ab7
	}
}

@keyframes rainbowAnimationFont {
	0% {
		color: #4408c5
	}

	10% {
		color: #8c09bc
	}

	20% {
		color: #ce0606
	}

	30% {
		color: #c56c06
	}

	40% {
		color: #caa906
	}

	50% {
		color: #aec806
	}

	60% {
		color: #7ec609
	}

	70% {
		color: #07be71
	}

	80% {
		color: #0aadb8
	}

	90% {
		color: #0a56ad
	}

	100% {
		color: #410ab7
	}
}