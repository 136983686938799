// import fonts for header
$fonts: (
	'AnotherDanger',
	'Avengers',
	'ComicSans',
	'DontStarve',
	'Dovahkiin',
	'Helvetica',
	'HollywoodHills',
	'MinecraftEnchant',
	'Minecraft',
	'OldLondon',
	'Prisma',
	'Rockwell',
	'RushTurbo',
	'Saddlebag',
	'ScaryThings',
	'Skyrim',
	'SquidGame',
	'StarWars',
	'TheCrowRegular',
	'TimesNewRoman',
	'Valorant',
	'GenshinImpact',
	'Upheavtt'
);

@each $font in $fonts {
  @font-face {
	font-family: $font;
	src: url(#{'../fonts/sarcophage/' + $font + '.ttf'}) format('truetype');
  }
}

@font-face {
	font-family: 'Umpush';
	src: url('../fonts/Umpush.ttf') format('truetype');
}

@font-face {
	font-family: 'Umpush-LightOblique';
	src: url('../fonts/Umpush-LightOblique.ttf') format('truetype');
}

@font-face {
	font-family: 'Umpush-Bold';
	src: url('../fonts/Umpush-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Umpush-BoldOblique';
	src: url('../fonts/Umpush-BoldOblique.ttf') format('truetype');
}