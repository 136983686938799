@import 'colors';
@import 'inputs';

.container {
	@include container;
	
	flex-direction: column-reverse;
}

.label {
	@include label;

	margin-right: auto;
	border-top-left-radius: $borderRadius;
	border-top-right-radius: $borderRadius;
}

.input {
	@include input;

	padding-top: calc($padding / 2);
	padding-bottom: calc($padding / 2);
	border-bottom-left-radius: $borderRadius;
	border-bottom-right-radius: $borderRadius;
	border-top-right-radius: $borderRadius;
}