.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.title {
	text-align: center;
}
