@import 'colors';
@import 'media';

.braces {
	width: 25%;
	pointer-events: none;
	user-select: none;
}

.braces_container {
	display: flex;
	justify-content: center;
}

.modalOverlay {
	position: fixed;
	inset: 0px;
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	border: 2px solid $lightBlack;
	background: rgb(255, 255, 255);
	overflow: auto;
	border-radius: 4px;
	outline: currentcolor none medium;
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;

	@include phone {
		padding-top: 5px;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

h1 {
	@include phone {
		font-size: 22px;
	}
}

.modalCloseButton {
	padding: 10px;
	cursor: pointer;
	text-align: right;
	margin-left: auto;

	img {
		width: 20px;
	}
}

.title {
	font-family: 'OldLondon';
	text-align: center;
	font-size: 5.2em;
	letter-spacing: 0.3em;
	margin-top: 20px;
	margin-bottom: 50px;
	user-select: none;
	text-shadow: 6px 6px 8px #999;
}

.discrete {
	color: $fontDiscrete;
	font-size: 16px;
	font-style: italic;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	padding-left: 25px;
	padding-right: 25px;
}

.quote_container {
	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 70%;
	margin-top: 60px;

	@include phone {
		width: 85%;
	}
}

.footer_message {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $fontDiscrete;
	font-size: larger;

	@include phone {
		font-size: medium;
	}
}

.confirm_delete {
	input[type=submit] {
		background-color: rgb(255, 226, 226);

		&:hover {
			background-color: rgb(143, 13, 13);
		}
	}
}
