@import 'colors';
@import 'media';

.label {
	position: absolute;
	top: -40px;
	user-select: none;

	@include phone {
		font-size: small;
		top: -60px;
		left: 0;
	}
}

@mixin nextQuote {
	font-family: 'Umpush';
	font-size: medium;
	position: absolute;
	right: 0;
	@include phone {
		font-size: small;
	}
	user-select: none;
}

$bottomNextQuote: -28px;
.nextQuoteTime {
	@include nextQuote;
	bottom: $bottomNextQuote - 20px;
	@include phone {
		bottom: $bottomNextQuote;
	}
}

.nextQuoteTimeLabel {
	@include nextQuote;
	bottom: $bottomNextQuote;
	@include phone {
		display: none;
	}
}

.quote {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 70%;
	font-family: 'Umpush-LightOblique';
	font-size: larger;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	filter: drop-shadow(0 0 5px grey);
	animation: quoteAnimation 10s infinite ease alternate;
	background: linear-gradient(45deg, #F17C58, #E94584, #24AADB, #27DBB1, #FFDC18, #FF3706);
	background-size: 600% 100%;
}

@keyframes quoteAnimation {
	0% {
		background-position: 0%
	}

	100% {
		background-position: 100%
	}
}

.text {
	z-index: 1;
	position: relative;

	font-family: 'Umpush-LightOblique';
	font-size: larger;
	text-align: center;

	@include phone {
		font-size: large;
	}
}

.text::before {
	content: '"';
	user-select: none;
}

.text::after {
	content: '"';
	user-select: none;
}

.author_year {
	z-index: 1;
	position: relative;

	font-family: 'Umpush-BoldOblique';
	font-size: large;
	text-align: center;
	width: 100%;
	margin-left: 5%;

	@include phone {
		font-size: medium;
	}
}