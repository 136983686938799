@import 'colors';
@import 'media';

$animationDuration: 40s;
$shift: 100px;

/*Gradient shadow https://www.youtube.com/watch?v=8r3_HLVaWwM*/
.quote {
	position: relative;
	width: 100%;
	font-family: 'Umpush-LightOblique';
	font-size: larger;
	padding: 10px;
	margin-bottom: 80px;
	border-radius: 5px;
	background: var(--background-gradient);
	background-size: 400% 400%;
	animation: quoteAnimation $animationDuration infinite ease alternate, quoteInsertAnimation 0.5s ease;
	margin-left: -$shift;

	@include phone {
		margin-left: 0px;
	}
}
.quote:nth-child(even) {
	margin-left: $shift;

	@include phone {
		margin-left: 0px;
	}
}
.quote::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: var(--background-gradient);
	background-size: 400% 400%;
	animation: quoteAnimation $animationDuration infinite ease alternate;
	filter: blur(30px);
	transform: translateY(30px)
}
@keyframes quoteAnimation {
  0%   {background-position: 0% 50%}
	50%  {background-position: 100% 50%}
	100% {background-position: 0% 50%}
}
@keyframes quoteInsertAnimation {
	0%   { transform: scale(0) }
	80%  { transform: scale(1.1) }
	100% { transform: scale(1) }
}

.text {
	z-index: 1;
	position: relative;

	font-family: 'Umpush-LightOblique';
	font-size: larger;
	text-align: center;

	@include phone {
		font-size: large;
	}
}
.text::before { content: '"'; user-select: none; }
.text::after { content: '"'; user-select: none; }

.author_year {
	z-index: 1;
	position: relative;

	font-family: 'Umpush-BoldOblique';
	font-size: large;
	text-align: center;
	width: 100%;
	margin-left: 5%;
	
	@include phone {
		font-size: medium;
	}
}

.adminButton {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 30px;
	@include phone {
		bottom: 20px;
	}

	button {
		@include phone {
			font-size: 12px;
			margin-right: 6px;
		}
		color: $fontDiscrete;
		font-size: 20px;
		background-color: transparent;
		border: none;
		text-decoration: underline;
		margin-right: 20px;
		transition: letter-spacing 0.3s;
		font-weight: 300;

		&:hover {
			font-weight: 500;
			color: $lightBlack;
			cursor: pointer;
			letter-spacing: 2px;
			transition: letter-spacing 0.2s;
		}
	}
}

.deleteButton {
	bottom: 0;
	button {
		&:hover {
			color: rgb(130, 10, 10);
		}
	}
}
