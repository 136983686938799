@import 'colors';
@import 'inputs';

.container {
	@include container;
	
	flex-direction: row-reverse;
}

.label {
	@include label;

	border-top-left-radius: $borderRadius;
	border-bottom-left-radius: $borderRadius;
}

.input {
	@include input;
	
	width: 100%;
	border-top-right-radius: $borderRadius;
	border-bottom-right-radius: $borderRadius;
}
